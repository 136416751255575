import React from 'react'
import _ from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'
import Hidden from '@material-ui/core/Hidden'
import HeaderItemTemplate from '~components/molecules/HeaderItem/template'
import Colors from '~shared/assets/styles/colors'
import { BonusBadge } from '~src/new/widgets/Badges/BonusBadge'

const styles = ({ breakpoints }) => ({
  root: {},
  points: {
    display: 'flex',
    marginRight: '10px',

    justifyContent: 'center',
    alignItems: 'center',

    '&:last-child': {
      marginRight: '0',
    },

    '& img': {
      width: '16px',
      height: '16px',

      marginLeft: '2px',
    },
  },
  point: {
    margin: '0',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '0.9',
    letterSpacing: '-0.03em',
    textTransform: 'uppercase',
    color: Colors.White,
    transition: `all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275)`,

    position: 'relative',
    top: '2px',
  },
  pointsNormal: {
    transform: `scale(1.0, 1.0)`,
  },
  pointsLarge: {
    transform: `scale(1.3, 1.3) rotate(5deg)`,
  },
  pointsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // padding: '10px',
    // borderRadius: '50px',
    // backgroundColor: Colors.Yellow,
    minWidth: '75px',
    height: '40px',
    position: 'relative',
    boxSizing: 'border-box',

    [breakpoints.down('xs')]: {
      minWidth: '40px',
    },
  },
  pointsDescription: {
    position: 'absolute',
    top: '110%',
    left: '0px',
    backgroundColor: Colors.White,
    borderRadius: '16px',
    padding: '20px 40px 30px',
    color: Colors.Black,
    zIndex: '2',

    boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.15)',

    display: 'none',
  },

  pointName: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    margin: '0',
    marginBottom: '5px',

    whiteSpace: 'nowrap',
    width: '100%',

    display: 'flex',
    justifyContent: 'space-between',

    '& span': {
      fontWeight: '700',
      textAlign: 'right',

      marginLeft: '10px',
    },
  },

  mr0: {
    marginRight: '0',
  },
})

class HeaderItemPoints extends React.Component {
  state = {
    prevPoints: undefined,
  }

  static getDerivedStateFromProps(props, state) {
    const {
      member: { jpoints },
    } = props
    const { prevPoints } = state

    if (!jpoints) return null

    if (prevPoints === undefined) {
      return { prevPoints: jpoints }
    }

    const changed = jpoints.filter(({ type, amount }) => {
      const prevPoint = prevPoints.find(({ type: prevType }) => prevType === type)

      return !prevPoint || prevPoint.amount !== amount
    })

    if (changed.length > 0) {
      return {
        prevPoints: jpoints,
        large: {
          ...state.large,
          ...changed.reduce((data, { type }) => ({ ...data, [type]: true }), {}),
        },
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.large) {
      setTimeout(() => {
        this.setState({ large: null })
      }, 100)
    }
  }

  render() {
    const { classes, icon, member, ...props } = this.props

    if (!member || !member.jpoints) return null
    let jpoints = _.cloneDeep(member.jpoints)

    if (
      jpoints?.length > 1 &&
      jpoints.every(points => ['default', 'business'].includes(points.type))
    )
      jpoints = jpoints.filter(points => points.type !== 'business')

    const isOnlyBusiness = jpoints.some(points => points.type === 'business')

    return (
      <Hidden>
        <HeaderItemTemplate {...props} label="Вознаграждение" labelColor={Colors.Yellow}>
          <div className={classes.pointsContainer}>
            <BonusBadge
              points={jpoints}
              variant={isOnlyBusiness ? 'circle' : 'large'}
              isMainPage={true}
            />
          </div>
        </HeaderItemTemplate>
      </Hidden>
    )
  }
}

export default withStyles(styles)(HeaderItemPoints)
